import triggerAnimationRestart from '../utils/triggerAnimationRestart';

const selectors = {
    countrySelectorDrawer: '[data-drawer="country-selector"]',
    countrySelectorOpenButton: '[data-drawer-toggle="country-selector"]',
    countrySelectorCloseButton: '[data-country-selector-close]',
    countrySelectorSearch: '[data-country-selector-search]',
    countrySelectorForm: '.localization-form',
};

const classes = {
    hasOpenDrawer: 'has-open-drawer',
    overlay: '.overlay',
    overlaySelector: 'overlay--country-selector',
};
class CountrySelector {
    constructor() {
        this.overlay = document.querySelector(classes.overlay);
        this.form = document.querySelector(selectors.countrySelectorForm);
        this.search = document.querySelector(selectors.countrySelectorSearch);
        this.countrySelectorDrawer = document.querySelector(
            selectors.countrySelectorDrawer
        );
        this.countrySelectorCloseButton = document.querySelector(
            selectors.countrySelectorCloseButton
        );
        this.countrySelectorOpenButton = document.querySelector(
            selectors.countrySelectorOpenButton
        );
        this.isDesktop = matchMedia('(min-width: 1300px)').matches;

        this.openButton();
        this.closeButton();
        this.handleOverlayClick();
        this.formSubmit();
        this.handleSearch();
    }

    openButton() {
        this.countrySelectorOpenButton.addEventListener('click', e => {
            e.preventDefault();
            this.countrySelectorDrawer.setAttribute(
                'data-drawer-state',
                'closed'
            );
            this.overlay.classList.add(classes.overlaySelector);
            this.overlay.setAttribute('data-state', 'hidden');
        });
    }

    closeButton() {
        this.countrySelectorCloseButton.addEventListener('click', e => {
            e.preventDefault();

            if (this.isDesktop) {
                this.closeDrawer();
            } else {
                this.CloseDrawerMobile();
            }
        });
    }

    handleSearch() {
        this.search.addEventListener('keyup', () => {
            const countries = document.querySelectorAll(
                '.country-selector__country-list-item'
            );
            const searchValue = this.search.value.toLowerCase();

            countries.forEach(country => {
                const countryName = country
                    .querySelector('.country-choice')
                    .innerText.toLowerCase();

                if (countryName.includes(searchValue)) {
                    // eslint-disable-next-line no-param-reassign
                    country.style.display = 'block';
                } else {
                    // eslint-disable-next-line no-param-reassign
                    country.style.display = 'none';
                }
            });
        });
    }

    handleOverlayClick() {
        this.overlay.addEventListener('click', e => {
            e.preventDefault();

            if (this.isDesktop) {
                this.closeDrawer();
            } else {
                this.CloseDrawerMobile();
            }
        });
    }

    formSubmit() {
        this.form.addEventListener('change', () => {
            this.form.submit();
        });
    }

    closeDrawer() {
        window.dispatchEvent(
            new CustomEvent('drawer.closed', { detail: 'drawer closed' })
        );
        this.countrySelectorDrawer.setAttribute('data-drawer-state', 'closed');
        this.overlay.classList.remove(classes.overlaySelector);
        this.overlay.setAttribute('data-state', 'hidden');
        document.body.classList.remove(classes.hasOpenDrawer);
    }

    CloseDrawerMobile() {
        if (
            this.countrySelectorDrawer.getAttribute('data-drawer-state') ===
            'open'
        ) {
            const animationEndListener = () => {
                this.countrySelectorDrawer.setAttribute(
                    'data-drawer-state',
                    'closed'
                );
                this.countrySelectorDrawer.removeEventListener(
                    'animationend',
                    animationEndListener
                );
            };
            this.countrySelectorDrawer.addEventListener(
                'animationend',
                animationEndListener
            );
            this.countrySelectorDrawer.setAttribute(
                'data-drawer-state',
                'closing'
            );
            if (!document.body.classList.contains(classes.hasOpenDrawer)) {
                this.overlay.setAttribute('data-state', 'hidden');
            }
            triggerAnimationRestart(
                this.countrySelectorDrawer,
                'data-drawer',
                this.countrySelectorDrawer.dataset.drawer
            );
            document.body.classList.remove(classes.overlaySelector);
        }
    }
}

export default CountrySelector;
